.stats-list{
	padding-left: 0;
	list-style: none;
	font-size: 16px;
	background-color: #fff;
	border: 1px solid #c8ced3;
	width: 100%;

	thead{
		background-color: #2f353a;
		color: #fff;
	}

	td, th{
		padding: 10px 15px;
	}

	tr:not(:last-child){
		border-bottom: 1px solid #c8ced3;
	}

	.title{
		font-weight: 600;
		margin-right: auto;
	}
	.value{
		margin-left: 10px;
	}
}