body {
  font-family: 'Poppins', sans-serif;
}

text {
  color: '#3E5B77';
}

.app-body .main {
  position: relative;
}
