.plan {
	background: #fdfbf9;
	border: 1px solid #eee4db;
	box-sizing: border-box;
	border-radius: 16px;
	padding: 24px 16px 16px 16px;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	min-height: 449px;
	min-width: 320px;
	width: 100%;
	position: relative;

	@media (min-width: 992px) {
		width: 300px;
		min-width: 300px;
	}

	@media (min-width: 1226px) {
		width: 320px;
		min-width: 320px;
	}

	&__title {
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		color: #495b75;
		text-align: center;
		margin-top: 0;
		margin-bottom: 24px;
	}

	&__condition-item {
		display: flex;
		margin-bottom: 12px;

		:last-child {
			margin-bottom: 0;
		}
	}

	&__condition-text {
		margin: 0;
		font-size: 16px;
		line-height: 20px;
	}

	&__condition-icon {
		margin-right: 12px;
	}

	&__price-container {
		display: flex;
		justify-content: center;
		color: #495b75;
		position: relative;
		margin: auto 0 28px 0;

		sup {
			font-weight: 600;
			font-size: 24px;
			line-height: 28px;
			display: inline-block;
			position: relative;
			top: 0;
		}
	}

	&__price-number {
		font-weight: 600;
		font-size: 40px;
		line-height: 40px;
	}

	&__price-remark {
		margin-left: 9px;
		text-align: left;
	}

	&__popular {
		position: absolute;
		left: -9px;
		bottom: 35px;
	}

	&__condition-list{
		padding-left: 0;
		text-align: left;
	}

	&__button{
		padding: 10px 16px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		outline: none;
		border: 2px solid;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		transition: all .3s ease-in-out;

		&.btn__gold{
			background-color: #c59e72;
			border-color: #c59e72;
			color: #fafaf9;

			&:hover{
				background-color: #b98d5b;
			}

			&.btn__ghost{
				background-color: transparent;
				color: #c59e72;

				&:hover{
					color: #b98d5b;
					border-color: #b98d5b;
				}
			}
		}
	}
}

.plans {
	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (min-width: 992px) {
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			min-width: 300px;
		}
	}

	&__title {
		margin: 0 0 40px 0;

		@media (min-width: 992px) {
			margin: 0 0 60px 0;
		}
	}
}
