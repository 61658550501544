@import "components/plan";
@import "components/stats-list";

.user-management {
  &__card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #4F5D75;
    color: #FFFF;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
  }

  &__card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    padding-bottom: 20px;
  }

  &__button {
    background-color: #eef4f5 !important;
  }

  &__date {
    margin-top: 20px;
    font-size: 14px;
  }

  &__confirm-modal {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-gap: 20px;

    &_two-rows {
      padding: 20px;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__with-discount {
    color: #73d13d;
    font-size: 16px;
  }
}

.countyCard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(260px, max-content);
  grid-gap: 30px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 789px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 588px) {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .countyCard {
    grid-gap: 20px;
  }

  .user-management {
    &__card {
      padding: 20px;
    }
  }
}

.re-captcha__wrapper{
  display: flex;
  justify-content: center;
}