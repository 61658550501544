.app-footer {
  padding-bottom: 30px;
  margin-top: 30px;
}
.footer {
  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    flex-wrap: wrap;
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and(max-width: 550px) {
    &__bottom {
      justify-content: center;
    }

    &__content {
      padding: 20px;
    }
  }
}

.footer-navigation {
  display: grid;
  list-style: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  @media screen and(max-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    text-align: center;
  }
}

.footer-content {
  &__left {
    flex: 0 0 40%;
  }
  &__right {
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__adress {
    margin-top: 20px;
  }

  @media screen and(max-width: 770px) {
    &__left {
      flex: 0 0 100%;
      margin-bottom: 20px;
      text-align: center;
    }
    &__right {
      flex: 0 0 100%;
    }
  }
}

.footer-bottom {
  &__long-text {
    flex: 0 0 50%;
    text-align: center;

    @media screen and(max-width: 550px) {
      flex: 0 0 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.main-text-style {
  font-size: 17px;
  font-family: Poppins;
}

.grid {
  display: grid;
  padding-right: 10px;
  align-items: center;

  &__row-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.main-btn {
  background-color: #021e49 !important;
  color: white !important;

  &_white {
    color: #021e49;
  }

  &_mt-20 {
    margin-top: 20px !important;
  }
}

.modal-clie {
  font-family: Poppins;

  &__content {
    width: 500px;
    display: grid;
    grid-gap: 20px;
  }
}

.main-title {
  color: #0d3559;
  font-family: Poppins;
  font-size: 35px;
  margin: 30px;
  text-align: center;
  font-weight: bold;
}

.input-clie {
  margin-top: 20px;
}

.row-clie {
  padding: 0 100px;
}

.header-card__item {
  margin-bottom: 4px !important;
  font-size: 24px !important;
  font-family: Poppins;
}

.h-35px {
  height: 35px !important;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
